<!--
data: 
- title: text
- subTitle: text
- text: text
- video: video
- bgText: text
- backgroundImage: image
- style: select (light: text, dark: text, color: text)
-->

<template>
	<div class="cs-video-block cs-block" :class="styleClass">
		<div class="cs-block-base">

			<div class="container">

				<div class="header-content">
                    <h2 class="cs-title" v-if="data.title"> {{data.title}}</h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">
                        {{ data.subTitle }}
                    </h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"/>
                </div>

				<div class="video-wrapper">
					<div :class="overlayClasses" @click="playVideo">
						<button class="revert">
							<svg width="18" height="20" viewBox="0 0 14 16" fill="white" xmlns="http://www.w3.org/2000/svg">
								<path d="M14 8L0.499999 15.7942L0.5 0.205771L14 8Z"/>
							</svg>
						</button>
					</div>
					<video ref="videoblock" :controls="videoPlayedOnce">
						<source :src="data.video" />
					</video>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
    import {computed} from '../libs/common-fn';
    
	export default {
		name: 'Video',
		components: {},
		props: {
			data: {
				type: Object,
                default: () => {},
			},
		},
		data() {
			return {
				videoPlayedOnce: false
			}
		},
		computed: computed('Video'),
		methods: {
			playVideo() {
                this.videoPlayedOnce = true;
                this.$refs.videoblock.play();
            },
        },
	};
</script>

<style lang="scss" scoped>
	@import '../styles/main.scss';

	@mixin cs-video-height {
		height: 600px;

		@media (max-width: 991.98px) { 
			height: 320px;
		}

		@media (max-width: 575.98px) {
			height: 220px;
		}
	}
	.cs-video-block {
        .header-content {
            text-align: center;
            margin-bottom: 10px;
        }
        .cs-block-base {
            width: 100%;
            position: relative;

            video {
                @include cs-video-height;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
            .video-wrapper{
                position: relative;
                height: 100%;
                width: 100%;

                .cs-video-overlay {
                    @include cs-video-height;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 300ms ease-in-out;

                    &.video-overlay-hidden {
                        filter: alpha(opacity=0);
                        opacity: 0;
                        pointer-events: none;
                    }

                    button {
                        @include base-transition;
                        background: transparent;
                        display: flex;
                        border: 2px solid $theme_color;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        height: 90px;
                        position: absolute;
                        svg {
                            fill: $theme_color;
                            @media (max-width: 991px) {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        @media (max-width: 991px) {
                            width: 70px;
                            height: 70px;
                        }
                        &:hover{
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
	}
</style>